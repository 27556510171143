const browserWindow = window || {};
// tslint:disable-next-line:no-string-literal
const browserWindowEnv = browserWindow['__env'] || {};

const origin_url = window.origin.split(":")

if (origin_url.length > 2) {
  origin_url.pop()
}

const api_url = origin_url.join(":") + '.siesaecommerce.com:8002' + '/'

export const constants = {
  config: {
    oauthClient: 'ZhEuGqWi3rpDEEixyoUBl9xwNEaeUUIMOZzPdCkl',
    // tslint:disable-next-line:max-line-length
    oauthSecret: 'KbxZHHwgeCDTIolbxjBfNwz2JWM0z2P1iXRt67aMrOPp5p4gxU3hqARTaV2aU1xHoCxCRz2C1xqv6F836RDCSAkfrXwjZdetLEEpRX6VBjMNKvWHs99i5xrZgjPmzY4S',
    apiUrl: browserWindowEnv['AGR_BACKEND_HOST'] || 'http://127.0.0.1:8000/',
    //apiUrl: 'http://127.0.0.1:8000/',
    // apiUrl: 'http://localhost:8002/',
    apiGatewayFront: browserWindowEnv.apiGatewayFront,
    siteKeyCaptcha: browserWindowEnv.siteKeyCaptcha,
    adverstismentApi: 'entry_point/e_get_cms_space/?&ad=0',
    adverstismentCmsSpaces: 'entry_point/e_get_cms_space/?ubicacion=',
    getInitData: 'entry_point/e_get_companies/',
    updateUser: 'entry_point/e_update_user/',
    getLocationByText: 'entry_point/agr_get_location_by_search_text/',
    getAffairs: 'entry_point/agr_get_affairs/',
    getCompanieInfo: 'entry_point/agr_get_companie_info/',
    getRelatedTo: 'entry_point/agr_get_related_to/',
    getContactThrough: 'entry_point/agr_get_contact_through/',
    sendMailContact: 'entry_point/agr_enviar_correo_contactenos/',
    sendMaildFailure: 'entry_point/agr_enviar_correo_zona_pagos/',
    // adverstismentCmsSpaces: 'entry_point/agr_cms_info/?ubicacion',
    getContentAllCms: 'entry_point/agr_cms_info_by_bussines_type/',
    updatePassword: 'entry_point/e_update_password/',
    userLogin: 'entry_point/e_user_login/',
    userLogout: 'entry_point/e_logout/',
    getContentAGR: 'entry_point/agr_get_content/',
    getContent_loginAGR: 'entry_point/agr_content_login/',
    getUserInfo: 'entry_point/agr_user_info/',
    recoverPassword: 'entry_point/agr_recover_password/',
    infoPage: 'entry_point/agr_info_page/',
    getTercerosCertificados: 'entry_point/agr_terceros_certificados/',
    myUsersList: 'entry_point/e_users_related_to_admin/',
    createUser: 'entry_point/e_create_update_user_by_admin/',
    emailValidator: 'entry_point/e_validate_email/',
    identificationValidatorAgr: 'entry_point/e_user_by_identification/',
    getUnpaidBill: 'entry_point/agr_consulta_cuentas_x_cobrar/',
    getPaymentHistory: 'entry_point/agr_consulta_historico_pagos/',
    getRelatedPaymentsForHistoryDetail: 'entry_point/agr_consulta_recibo_caja_detalle/',
    postPayBills: 'entry_point/agr_grabar_recaudo/',
    recaudoPay: 'entry_point/agr_pay_gate_new/',
    getPayInfo: 'entry_point/agr_get_pay_info/',
    getMediosPago: 'entry_point/paygates_get_medios_pago/',
    getPasarelaPago: 'entry_point/paygates_get_bancos_pagos/',
    invoiceCmsMensajePago: 'template/cms-mensaje-pago',
    servicesTimeOut: 25000,
    getRetornarDiasErpBackend: 'entry_point/agr_retornar_dias_erp_backend/',
    getConsolidatedPaymentHistory: 'entry_point/agr_consulta_dashboard_historico_pagos/',
    getConsolidatedInvoices: 'entry_point/agr_consulta_consolidado_cartera/',
    getDashboardAgr: 'entry_point/agr_consulta_dashboard/',
    getDashboardHistoricoPagos: 'entry_point/agr_consulta_dashboard_historico_pagos/',
    getCompaniaPrincipal: 'entry_point/agr_consulta_compania_principal/',
    getRecaudodGenerados: 'entry_point/agr_consultar_recaudos_generados/?data',
    sendInvitationUser: 'entry_point/e_send_invitation_user/',
    enableOrDisableUser: 'entry_point/e_enable_user_admin/',
    userData: 'entry_point/e_preload_user_created_by_admin/',
    getBlocked: 'entry_point/agr_consulta_bloqueo_cliente/',
  },
  commons: {
  }
};

console.log(constants.config.apiUrl)
