import { Component, Renderer2, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from './services/header/header.service';
import { DOCUMENT } from '@angular/common';
import { EnvService } from './services/utils/env.service';
import { ManageLocalStorageService } from './services/manage-localstorage-service/manage-localstorage-service';
import { InactivityService } from './services/inactivity/inactivity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'zona de pagos';
  flagOffline: boolean;
  contentB2s: any;


  constructor(private renderer: Renderer2,
    private headerService: HeaderService,
    private _inactivity: InactivityService,
    private _router: Router,
    private env: EnvService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private _manageLocalStorage: ManageLocalStorageService) {

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((event.url === '/login') || (event.url === '/register-login') || (event.url === '/password') ||
          (event.url === '/password-recovery') || (event.url.length === 1)) {
          // this.renderer.addClass(document.body, 'bg-gradient-primary');
          this.renderer.addClass(document.body, 'body-login');
        } else {
          const selectedCompany = localStorage.getItem('selectedCompany');
          this.statusServer(selectedCompany);

          // this.renderer.removeClass(document.body, 'bg-gradient-primary');
          this.renderer.removeClass(document.body, 'body-login');
        }

        if (event.url.indexOf('backend') > -1) {
          window.location.href = `${this.env.apiGatewayBackOffice}`;
        }

        (window as any).ga('set', 'page', event.urlAfterRedirects);
        (window as any).ga('send', 'pageview');

        this._manageLocalStorage.limitNavigation(event.url);
      }
    });

    this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
    this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
  }

  statusServer(selectedCompany) {
    this.headerService.getAGRParams(selectedCompany)
      .subscribe((paramResponse: any) => {
        this.flagOffline = paramResponse.data.pageOnline;
        if (paramResponse.error || !this.flagOffline) {
          this._router.navigate(['/offline-expectation']);
        }
        this.contentB2s = paramResponse.data;
        localStorage.setItem('logo', this.contentB2s.logo);
        localStorage.setItem('imagen_login', this.contentB2s.imagen_login);
        localStorage.setItem('favicon', this.contentB2s.img_favicon);
        localStorage.setItem('page_title', this.contentB2s.page_title);
        localStorage.setItem('contact', this.contentB2s.contact);
        localStorage.setItem('terms', this.contentB2s.terms);
        localStorage.setItem('old_payments_firts', this.contentB2s.old_payments_firts);
        localStorage.setItem('pago_cualquier_factura', this.contentB2s.pago_cualquier_factura);
        localStorage.setItem('permite_pagar_abonos', this.contentB2s.permite_pagar_abonos_facturas);
        localStorage.setItem('collection_strategy', this.contentB2s.collection_strategy);
        localStorage.setItem('consolidated', this.contentB2s.consolidated);
        localStorage.setItem('price_decimals', this.contentB2s.price_decimals);
        localStorage.setItem('agr_parameterization_time_out', this.contentB2s.agr_parameterization_time_out);
        localStorage.setItem('collection_modality', this.contentB2s.collection_modality);
        localStorage.setItem('bloqueo_factura_en_estado_de_pago', this.contentB2s.bloqueo_factura_en_estado_de_pago);
        localStorage.setItem('ingresar_descripcion_recibo_caja', this.contentB2s.ingresar_descripcion_recibo_caja);
        localStorage.setItem('valor_pagar_cero', this.contentB2s.valor_pagar_cero);
        localStorage.setItem('ingresar_valor_minimo_documentos', this.contentB2s.ingresar_valor_minimo_documentos);
        localStorage.setItem('valor_minimo_abono_agr', this.contentB2s.valor_minimo_abono_agr);
        localStorage.setItem('valor_minimo_anticipo_agr', this.contentB2s.valor_minimo_anticipo_agr);

        this._inactivity.initInactivityTimer(parseInt(this.contentB2s.agr_parameterization_time_out));
        this._document.getElementById('app-favicon').setAttribute('href', localStorage.getItem('favicon'));
        this._document.getElementById('page_title').innerText = localStorage.getItem('page_title');
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        }
      });
  }
}

