export const environment = {
  production: false,
  clientName: 'siesa',
  apiGatewayFront: 'https://adminagr.diabonos.com/',
  apiGatewayBackOffice: 'https://adminagr.diabonos.com/',
  apiUrl: 'https://adminagr.diabonos.com/',
  urlPath: 'https://adminagr.diabonos.com/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  siteKeyCaptcha: '6Lebs2IpAAAAAAvzyOHs0MgvZ-WC_WwXKugtYFR7',
  googleAnalyticsCode: 'UA-140785247-1',

 

};


