import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { itemsActionReducer } from '../../store/reducers/payments-items.reducer';
import { PaymentMethodReducer } from '../../store/reducers/payments-method.reducer';
import { CurrentPaymentMethodReducer } from '../../store/reducers/current-payment-method.reducer';

@NgModule({
	imports: [
	StoreModule.forFeature('itemsState', itemsActionReducer),
	StoreModule.forFeature('paymentsMethod', PaymentMethodReducer),
	StoreModule.forFeature('currentPaymentsMethodItem', CurrentPaymentMethodReducer)
	],
})

export class PaymentsItemsStoreModule {}